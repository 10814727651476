import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { NgbOffcanvas, OffcanvasDismissReasons, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'dashboard.component.html',
    providers: [LayoutService],
    standalone: false
})

export class DashboardComponent implements OnInit {
    constructor(public service: LayoutService, private offcanvasService: NgbOffcanvas) { }

    ngOnInit() { }
}