import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular';

@Injectable()
export class CustomErrorHandler extends SentryErrorHandler implements ErrorHandler {
    constructor(options?: ErrorHandlerOptions) {
        // Make sure to pass any required configuration to the parent constructor if needed
        super(options);
    }

    handleError(error: any): void {

        // These happen when we release a new version of the app :()
        if (error instanceof Error && error.message) {
            if (error.message.includes('Failed to fetch dynamically imported module') || (error.message.includes('error loading dynamically imported module')) ||
            error.message.includes('Importing a module script failed')) {
                window.location.reload();
                return;
            }
            if (error.message.includes('The operation was aborted')) {
                // don't care about this error, just means they clicked off of it like a video or something
                return;
            }
            // these 2 errors are a bug in the oauth provider, it is nothing.
            if (error.message.includes('Validating access_token failed, wrong state/nonce.') || error.message.includes('Right side of assignment cannot be destructured')) {
                return;
            }
            if (error.message.includes('\'text/html\' is not'))
            {
                // this seems to happen sometimes when we release a new version
                window.location.reload();
                return;
            }
            if (error.message.includes('The connection was stopped during negotiation.') || error.message.includes('Failed to complete negotiation with the server') ||
            error.message.includes('The connection was stopped before the hub handshake could complete.'))
            {
                // this is when they click away fast from a course page and signalr starts to try to connect but does not have time to finish
                return;
            }
            if (error.message.includes('Cannot read properties of undefined (reading \'grant_type\')')) {
                return;
            }
            if (error.message.includes("NG0701"))
            {
                //this just means their current culture is one that our (poor) localization does not support
                return;
            }
            if (error.message.includes('AbortError')) {
                // this just means they navigated away, not an issue
                return;
            }
            if (error.message.includes('(this.filterBy || this.optionLabel ||'))
            {
                // this is a bug in primeng, ignore it
                return;
            }

        }
        super.handleError(error); // Call Sentry's error handler to log the error
    }
}