import { TenantBoxService } from '@abp/ng.account.core';
import { Component } from '@angular/core';

@Component({
    selector: 'abp-tenant-box',
    templateUrl: './tenant-box.component.html',
    providers: [TenantBoxService],
    standalone: false
})
export class TenantBoxComponent {
  constructor(public service: TenantBoxService) {}
}
