import { CoreModule } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { UrlForwardingDto, UrlForwardingService } from '@proxy/url-forwardings';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-url-forwarding-tab',
    templateUrl: 'url-forwarding-tab.component.html',
    imports: [CoreModule, SharedModule]
})
export class UrlForwardingTabComponent implements OnInit {
	public urlPairs: UrlForwardingDto[] = [];
	public isSaving: boolean = false;
	public loading: boolean = true;
	public isDeleting: boolean = false;
	public emptyUrlMessage: string = 'Please fill out all URLs';
	public areAnyUrlsEmpty: boolean = false;

	constructor(
		private _urlForwardingService: UrlForwardingService,
		private _toasterService: ToasterService
	) {}

	ngOnInit(): void {
		this.refreshData();
	}

	private refreshData() {
		this.loading = true;
		this._urlForwardingService.getUrls().subscribe(data => {
			this.urlPairs = data;
			this.loading = false;
		});
	}

	addPair() {
		this.urlPairs.push({ oldUrl: '', newUrl: '' });
	}

	saveChanges() {
		if (this.urlPairs.some(pair => !pair.oldUrl.trim() || !pair.newUrl.trim())) {
			this.areAnyUrlsEmpty = true;
			return;
		} else {
			this.areAnyUrlsEmpty = false;
		}
		this.areAnyUrlsEmpty = false;
		this.isSaving = true;
		this._urlForwardingService.createOrUpdate(this.urlPairs).subscribe({
			next: () => {
				this.isSaving = false;
				this.refreshData();
				this._toasterService.success('Successfully saved URLs');
			},
			error: () => {
				this.isSaving = false;
				this._toasterService.error('There was an issue saving the URLs');
			},
		});
	}

	deleteUrlPair(id: string, index: number) {
		if (!id) {
			if (this.urlPairs.length > 1) {
				this.urlPairs.splice(index, 1);
			}
			return;
		}
		this.isDeleting = true;
		this._urlForwardingService.deleteUrl(id).subscribe({
			next: () => {
				this.isDeleting = false;
				this.refreshData();
				this._toasterService.success('Successfully deleted URLs');
			},
			error: () => {
				this.isDeleting = false;
				this.refreshData();
				this._toasterService.error('There was an issue deleting the URLs');
			},
		});
	}
}
