import { eLayoutType, SubscriptionService, ConfigStateService } from '@abp/ng.core';
import { collapseWithMargin, slideFromBottom } from '@abp/ng.theme.shared';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'abp-layout-application',
    templateUrl: './application-layout.component.html',
    animations: [slideFromBottom, collapseWithMargin],
    providers: [LayoutService, SubscriptionService],
    standalone: false
})
export class ApplicationLayoutComponent implements OnInit, AfterViewInit {
	// required for dynamic component
	static type = eLayoutType.application;
	public isAuthenticated: boolean = false;

	public noPageMargins: boolean = false;
	public noDashboard: boolean = false;
	public noPageContentErrorContainer: boolean = false;
	public addPageMargins: boolean = false;
	public userRole: string;

	public noPageMarginsRoutes: string[] = ['support', 'view-course'];
	public noDashboardRoutes: string[] = ['view-course'];
	public noPageContentErrorContainerRoutes: string[] = [
		'quiz',
		'notes',
		'exam-pretest',
		'video',
		'flashcard',
		'pdf',
	];
	public addPageMarginsRoutes: string[] = [
		'identity',
		'openiddict',
		'language-management',
		'text-template-management',
		'audit-logs',
		'setting-management',
	];

	constructor(
		public service: LayoutService,
		private _config: ConfigStateService,
		private router: Router
	) {}

	smallScreen() {
		return window.innerWidth < 992;
	}

	ngOnInit() {
		this._config.getOne$('currentUser').subscribe(currentUser => {
			this.userRole = currentUser.roles[0];
			if (currentUser['isAuthenticated']) this.isAuthenticated = true;
		});

		const url = this.router.url;
		this.noPageMargins = this.noPageMarginsRoutes.some(str => url.includes(str));
		this.noDashboard = this.noDashboardRoutes.some(str => url.includes(str));
		this.noPageContentErrorContainer = this.noPageContentErrorContainerRoutes.some(str =>
			url.includes(str)
		);
		this.addPageMargins = this.addPageMarginsRoutes.some(str => url.includes(str));

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				const url = event.urlAfterRedirects;
				this.noPageMargins = this.noPageMarginsRoutes.some(str => url.includes(str));
				this.noDashboard = this.noDashboardRoutes.some(str => url.includes(str));
				this.noPageContentErrorContainer = this.noPageContentErrorContainerRoutes.some(
					str => url.includes(str)
				);
				this.addPageMargins = this.addPageMarginsRoutes.some(str => url.includes(str));
			}
		});
	}

	ngAfterViewInit() {
		this.service.subscribeWindowSize();
	}
}
