import { ABP, RoutesService, TreeNode, ConfigStateService } from '@abp/ng.core';
import {
	Component,
	ElementRef,
	Input,
	QueryList,
	Renderer2,
	TrackByFunction,
	ViewChildren,
	OnInit,
} from '@angular/core';
import { UserMenuService } from '@abp/ng.theme.shared';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'abp-routes',
    templateUrl: 'routes.component.html',
    standalone: false
})
export class RoutesComponent implements OnInit {
	public isDropdownOpen: boolean = false;

	dashboardIcons = [
		'isax-teacher',
		'isax-people',
		'isax-personalcard',
		'isax-clipboard-text',
		'isax-tick-circle',
		'isax-profile-2user',
		'isax-book',
		'isax-video',
		'isax-house',
		'isax-book-1',
		'isax-user',
		'isax-dollar-square',
		'isax-archive-book',
		'isax-edit-2',
		'isax-people',
		'isax-key',
		'isax-login',
		'isax-receipt',
		'isax-support',
		'isax-setting-2',
		'isax-note-text',
		'isax-document-1',
		'isax-chart-21'
	];

	getIconIndex(name: string) {
		switch (name) {
			case '::Menu:Students':
				return 0;
			case '::Menu:Partners':
				return 1;
			case '::Menu:Enrollments':
				return 2;
			case 'Reports':
				return 3;
			case '::Menu:Approvals':
				return 4;
			case '::Menu:Leads':
				return 5;
			case '::Menu:MediaFiles':
				return 6;
			case '::Menu:Home':
				return 8;
			case 'My Courses':
				return 8;
			case 'Browse Courses':
				return 9;
			case 'Profile':
				return 10;
			case 'Payment History':
				return 11;
			case 'Certifications':
				return 12;
			case 'CEs':
				return 13;
			case 'Groups':
				return 14;
			case 'Codes':
				return 15;
			case 'Enrollment':
				return 2;
			case 'Students':
				return 0;
			case 'Login Info':
				return 16;
			case 'My Profile':
				return 10;
			case 'Billing':
				return 17;
			case 'Support':
				return 18;
			case 'Settings':
				return 19;
			case 'Members':
				return 5;
			case 'Content':
				return 20;
			case 'Registrations':
				return 11;
			case 'CMS':
				return 22;
			case 'Charts':
				return 22;
			default:
				return 21;
		}
	}

	@Input() smallScreen?: boolean;

	@ViewChildren('childrenContainer') childrenContainers!: QueryList<ElementRef<HTMLDivElement>>;

	rootDropdownExpand = {} as { [key: string]: boolean };

	trackByFn: TrackByFunction<TreeNode<ABP.Route>> = (_, item) => item.name;

	allowedRoutes: TreeNode<ABP.Route>[] = [];

	private userAllowed = {
		Student: ['Certifications', 'CEs'],
		Partner: [
			'Groups',
			'Codes',
			'Enrollment',
			'Students',
			'Login Info',
			'My Profile',
			'Billing',
			'Support',
			'::Menu:Home',
			'Registrations',
			'Reports',
		],
		admin: [
			'Dashboard',
			'Charts',
			'::Menu:Approvals',
			'Members',
			'::Menu:Partners',
			'::Menu:Leads',
			'Content',
			'Registrations',
			'Reports',
			'Settings',
			'AbpUiNavigation::Menu:Administration',
		],
		adminLimited: [
			'Dashboard',
			'::Menu:Approvals',
			'Members',
			'::Menu:Partners',
			'::Menu:Leads',
			'Registrations',
			'Reports',
			'Settings',
		],
		Marketing: ['Settings'],
	};

	public userRole: string;

	constructor(
		public readonly routesService: RoutesService,
		protected renderer: Renderer2,
		private _config: ConfigStateService,
		private _userMenuService: UserMenuService,
		private _router: Router
	) {}

	// Get name from child route to use as ID for collapsible container
	extractName(name: string) {
		const regex = /(\w+)$/g;
		return name.match(regex)[0];
	}

	ngOnInit() {
		this._config.getOne$('currentUser').subscribe(currentUser => {
			if (currentUser.roles.includes('Student')) {
				this.userRole = 'Student';
			} else if (currentUser.roles.includes('Partner')) {
				this.userRole = 'Partner';
			} else if (currentUser.roles.includes('admin')) {
				this.userRole = 'admin';
			} else if (currentUser.roles.includes('Admin - Limited')) {
				this.userRole = 'Admin - Limited';
			} else if (currentUser.roles.includes('Marketing')) {
				this.userRole = 'Marketing';
			}

			this.routesService.visible$.subscribe(results => {
				this.allowedRoutes = [];
				results.forEach(route => {
					if (this.userRole === 'Student') {
						if (this.userAllowed.Student.includes(route.name)) {
							if (route.name === 'Certifications') {
								// Add child routes dropdown certifications
								route.children = [
									{
										name: 'Browse Certifications',
										path: '/student-certifications/browse-student-certifications',
										parentName: route.name,
										isLeaf: true,
										parent: route,
										children: [],
										order: 2,
										iconClass: 'browse-certifications-icon',
									},
									{
										name: 'My Certifications',
										path: '/student-certifications/my-certifications',
										parentName: route.name,
										isLeaf: true,
										parent: route,
										children: [],
										order: 3,
										iconClass: 'my-certifications-icon',
									},
									{
										name: 'Renew Certification',
										path: '/student-certifications/renew-certification',
										parentName: route.name,
										isLeaf: true,
										parent: route,
										children: [],
										order: 4,
										iconClass: 'renew-certification-icon',
									},
								];
							}
							if (route.name === 'CEs') {
								// Add child routes dropdown CEs
								route.children = [
									{
										name: 'Browse CEs',
										path: 'ces/browse-ce-courses',
										parentName: route.name,
										isLeaf: true,
										parent: route,
										children: [],
										order: 2,
										iconClass: 'get-ces-icon',
									},
									{
										name: 'My CEs',
										path: '/ces/my-ces',
										parentName: route.name,
										isLeaf: true,
										parent: route,
										children: [],
										order: 3,
										iconClass: 'my-ces-icon',
									},
									{
										name: 'Import CEs',
										path: '/ces/import-ces',
										parentName: route.name,
										isLeaf: true,
										parent: route,
										children: [],
										order: 4,
										iconClass: 'import-ces-icon',
									},
								];
							}
							this.allowedRoutes.push(route);
						}
					} else if (this.userRole === 'Partner') {
						if (this.userAllowed.Partner.includes(route.name)) {
							if (route.name === 'Support') {
								// Add child routes dropdown to support
								route.children = [
									{
										name: 'FAQs',
										path: '/support/faq',
										isLeaf: true,
										parent: route,
										parentName: route.name,
										children: [],
										order: 1,
									},
									{
										name: 'Resources',
										path: '/support/resources',
										parentName: route.name,
										isLeaf: true,
										parent: route,
										children: [],
										order: 2,
									},
									{
										name: 'Contact Us',
										path: '/support/contact-us',
										parentName: route.name,
										isLeaf: true,
										parent: route,
										children: [],
										order: 3,
									},
								];
							}
							this.allowedRoutes.push(route);
						}
					} else if (this.userRole === 'admin') {
						if (this.userAllowed.admin.includes(route.name)) {
							this.allowedRoutes.push(route);
						}
					} else if (this.userRole === 'Admin - Limited') {
						if (this.userAllowed.adminLimited.includes(route.name)) {
							this.allowedRoutes.push(route);
						}
					} else if (this.userRole === 'Marketing') {
						if (this.userAllowed.Marketing.includes(route.name)) {
							this.allowedRoutes.push(route);
						}
					}
				});
			});
		});

		this._router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.closedCollapsedValues();
			}
		});
	}
	// Keep track of collapsed menu items {string: boolean}
	public collapsedValues = [];

	updateCollapsedValues(routeName: string) {
		if (this.userRole === 'Student') {
			if (this.collapsedValues[this.extractName(routeName)]) {
				this.collapsedValues[this.extractName(routeName)] = false;
			} else {
				this.collapsedValues = this.collapsedValues.map(() => false);
				this.collapsedValues[this.extractName(routeName)] =
					!this.collapsedValues[this.extractName(routeName)];
			}
		} else {
			this.collapsedValues[this.extractName(routeName)] =
				!this.collapsedValues[this.extractName(routeName)];
		}
	}

	closedCollapsedValues() {
		if (this.userRole === 'Student') {
			this.collapsedValues = this.collapsedValues.map(() => false);
		}
	}

	getSvg(name: string) {
		return `/src/assets/icons/student-menu/${name}.svg`;
	}
}
